import axios from "axios";
import Vue from "vue";

import { i18n } from "../plugins/i18n";

export const API_ORIGIN = localStorage.getItem(
  "VUE_APP_REAL_ESTATE_API_GATEWAY_URL"
);
export const BASE_API_URL = API_ORIGIN + "/api/v1";
export const BASE_API_URL_V2 = API_ORIGIN + "/api/v2";
export const TRANSLATIONS_BASE_API_URL = API_ORIGIN;

export const authInterceptor = [
  config => {
    if (Vue.prototype.$token()) {
      config.headers["Accept-language"] = i18n.locale;
      config.headers.Authorization = `Bearer ${Vue.prototype.$token()}`;
      config.headers["x-merchant"] = Vue.prototype.$merchant;
    }
    return config;
  },
  error => Promise.reject(error)
];

export const Service = url =>
  axios.create({
    baseURL: BASE_API_URL + url
  });

export const ServiceV2 = url =>
  axios.create({
    baseURL: BASE_API_URL_V2 + url
  });

export const AuthorizedService = url => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  return authorizedService;
};

export const AuthorizedServiceV2 = url => {
  const authorizedService = ServiceV2(url);
  authorizedService.interceptors.request.use(...authInterceptor);
  return authorizedService;
};

export const translationsService = async () => {
  return axios.get(TRANSLATIONS_BASE_API_URL + "/json-i18n/?reset=yes", {
    headers: {
      Authorization: `Bearer ${Vue.prototype.$token()}`,
      "x-merchant": Vue.prototype.$merchant
    }
  });
};
