<template>
  <v-layout wrap justify-center>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 lg10>
        <v-layout class="pt-5" wrap>
          <v-flex grow pa-1 xs10 sm7 md8>
            <v-layout align-start wrap>
              <h2 class="display-2 pl-4">{{ $t("User List") }}</h2>
            </v-layout>
          </v-flex>
          <v-flex shrink pa-1 xs6 text-xs-right> </v-flex>
        </v-layout>

        <v-layout>
          <v-flex class="flex-wrap" xs12 pa-1>
            <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
              <template #divider>
                <v-icon>fiber_manual_record</v-icon>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>

        <table-loader v-if="isLoading" />
        <v-card v-if="!isLoading">
          <v-toolbar class="re-toolbar re-wrap">
            <div class="re-flex">
              {{ $t("Rows Per Page") }}
              <v-radio-group v-model="row" class="re-radios">
                <v-radio :label="$t('All')" value="show-all" />
              </v-radio-group>
            </div>
          </v-toolbar>
          <v-layout style="overflow: auto">
            <v-data-table
              hide-actions
              class="width100"
              :headers="headers"
              :items="usersList"
              :options.sync="pagination"
              :server-items-length="pagination.totalItems"
              :no-data-text="$t('No data available')"
            >
              <template v-slot:items="props">
                <tr @click="dialog = true">
                  <td
                    class="re-orange-color"
                    :class="{
                      'text-xs-right': $vuetify.rtl,
                      'text-xs-left': !$vuetify.rtl
                    }"
                  >
                    <div>{{ props.item.id }}</div>
                  </td>
                  <td class="pa-0 text-xs-center">
                    <div>{{ props.item.email }}</div>
                  </td>
                  <td class="pa-0 text-xs-center">
                    <div>{{ props.item.user_id }}</div>
                  </td>
                  <td class="re-td text-xs-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">more_vert</v-icon>
                      </template>
                      <v-list>
                        <v-list-tile
                          class="list_tile"
                          style="cursor: pointer"
                          @click="
                            $router.push({
                              name: 'edit_user_permissions',
                              params: { id: props.item.id }
                            })
                          "
                        >
                          <v-list-tile-title>{{
                            $t("Edit")
                          }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-layout>
        </v-card>
        <div class="overflow-hidden text-xs-center pt-4" v-if="!isLoading">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
            class="re-pagination"
            @input="fetchData(false)"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import permissionService from "../../services/plugins/permission";
import TableLoader from "../loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";
export default {
  name: "UserListing",
  props: ["on"],
  mixins: [breadItemsSetLang, paginationMixin],
  components: { TableLoader },
  data() {
    return {
      isLoading: true,
      usersList: [],
      row: ""
    };
  },
  async created() {
    this.fetchData();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ID"),
          value: "id",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Email"),
          align: "center",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t("User Id"),
          align: "center",
          sortable: false,
          value: "user_id"
        },
        { text: this.$t("Action"), align: "center", sortable: false }
      ];
    }
  },
  methods: {
    async fetchData(blockLoading) {
      if (!blockLoading) {
        this.isLoading = true;
      }
      try {
        let params = {
          ...this.setPaginationParams()
        };
        const resp = await this.$processReq(
          permissionService.getUsersListing(params),
          false
        );
        this.usersList = resp.data.body.results;
        this.setPagination(resp.data.body.count);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to get Users list - ", err);
      }
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchData();
    },
    row() {
      this.fetchData();
    }
  }
};
</script>
