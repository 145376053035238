import { render, staticRenderFns } from "./UserGroupCreateOrEdit.vue?vue&type=template&id=7b227ebc&scoped=true"
import script from "./UserGroupCreateOrEdit.vue?vue&type=script&lang=js"
export * from "./UserGroupCreateOrEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b227ebc",
  null
  
)

export default component.exports