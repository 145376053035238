import { PluginServiceV2 } from "./index";

const permissions = PluginServiceV2("/object-level-permission-groups/");
const users = PluginServiceV2("/users");

export default {
  getObjectLevelGroupPermissions(params) {
    return permissions({
      method: "GET",
      url: "/",
      params
    });
  },
  getSpecificObjectLevelGroupPermissions(id) {
    return permissions({
      method: "GET",
      url: `/${id}`
    });
  },
  updateSpecificObjectLevelGroupPermissions(id, data) {
    return permissions({
      method: "PUT",
      url: `/${id}`,
      data
    });
  },
  createObjectLevelGroupPermissions(data) {
    return permissions({
      method: "POST",
      url: `/`,
      data
    });
  },
  getUsersListing(params) {
    return users({
      method: "GET",
      url: `/`,
      params
    });
  },
  getSpecificUserDetails(id) {
    return users({
      method: "GET",
      url: `/${id}`
    });
  },
  updateSpecificUserDetails(id, data) {
    return users({
      method: "PUT",
      url: `/${id}`,
      data
    });
  }
};
