<template>
  <div>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-layout class="pt-5" row wrap>
            <v-flex grow pa-1 xs12 sm5 md8>
              <v-layout align-start row wrap>
                <v-flex xs6 md4>
                  <h2 class="display-2 re-add-ml">
                    {{ $t("Profile Group") }}
                  </h2>
                </v-flex>
                <v-flex xs6 md8> </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="flex-wrap" xs12 pa-1>
              <v-breadcrumbs :items="breadItemsSetLang()" class="re-add-ml">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <v-card>
            <v-card-text class="pa-3">
              <v-text-field
                v-model="profileGroupName"
                :label="$t('Profile Group Name')"
                :disabled="isEdit"
                :rules="[(value) => !!value || requiredMessage]"
              />
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <table-loader v-if="isLoading" />
          <v-card v-if="!isLoading">
            <v-toolbar height="auto" class="re-toolbar re-wrap">
              <div class="re-flex">
                {{ $t("Rows Per Page") }}
                <v-radio-group v-model="row" row class="re-radios">
                  <v-radio :label="$t('All')" value="show-all"></v-radio>
                </v-radio-group>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                hide-details
                :placeholder="$t('Search')"
                v-model="searchFilter.search"
                single-line
              />
              <v-spacer />
            </v-toolbar>
            <v-layout style="overflow: auto">
              <v-data-table
                :headers="headers"
                :items="profiles"
                :no-data-text="$t('No data available')"
                hide-actions
                class="elevation-1 width100"
                :options.sync="pagination"
                :server-items-length="pagination.totalItems"
              >
                <template slot="headers" slot-scope="props">
                  <tr>
                    <th>
                      <v-checkbox
                        :input-value="areAllSelected"
                        :indeterminate="
                          selectedProfiles.length > 0 && !areAllSelected
                        "
                        @change="toggleAll"
                        primary
                        hide-details
                      ></v-checkbox>
                    </th>
                    <th v-for="header in props.headers" :key="header.text">
                      {{ header.text }}
                    </th>
                  </tr>
                </template>

                <template slot="items" slot-scope="props">
                  <tr>
                    <td>
                      <v-checkbox
                        :input-value="isSelected(props.item.id)"
                        @change="toggle(props.item.id)"
                        primary
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td class="re-text-center">
                      {{ props.item.profile_name }}
                    </td>
                    <td class="re-text-center">
                      {{ props.item.profile_name_en }}
                    </td>
                    <td class="re-text-center">
                      {{ props.item.profile_name_ar }}
                    </td>
                    <td class="re-text-center">
                      {{ props.item.phone }}
                    </td>
                    <td class="re-text-center">
                      {{ props.item.email }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card>
          <template>
            <div class="overflow-hidden text-xs-center pt-4">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                class="re-pagination"
                @input="loadProfiles()"
              ></v-pagination>
            </div>
            <div class="overflow-hidden text-xs-center pt-4">
              <v-btn
                :loading="isSubmitting"
                dark
                @click="submit()"
                class="re-orangtbtn re-ml-4"
              >
                {{ $t("Save") }}
              </v-btn>
            </div>
          </template>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import { debounce } from "lodash";

import permissionService from "../../services/plugins/permission";
import profileService from "../../services/plugins/profile";
import TableLoader from "../loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";
export default {
  name: "ProfileGroupCreateOrEdit",
  props: ["on"],
  mixins: [breadItemsSetLang, paginationMixin],
  components: { TableLoader },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      profiles: [],
      selectedProfiles: [],
      type: 2,
      row: "",
      isEdit: false,
      profileGroupName: "",
      fetchProfiles: null
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.getSelectedGroupProfiles(this.$route.params.id);
    }
    this.loadProfiles();
  },
  mounted() {
    this.fetchProfiles = debounce(this.loadProfiles, 500);
  },
  computed: {
    requiredMessage() {
      return this.$t("This field is required");
    },
    headers() {
      return [
        {
          text: this.$t("Profile Name"),
          value: "profile_name",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Profile Name EN"),
          value: "profile_name_en",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Profile Name AR"),
          value: "profile_name_ar",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Phone"),
          value: "phone",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Email"),
          value: "email",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        }
      ];
    },
    areAllSelected() {
      return this.profiles.every((profile) =>
        this.selectedProfiles.includes(profile.id)
      );
    }
  },
  methods: {
    async submit() {
      if (!this.profileGroupName) {
        this.profileGroupName = null;
        return;
      }
      this.isSubmitting = true;
      try {
        let params = {
          type: this.type,
          name: this.profileGroupName,
          profiles: this.selectedProfiles,
          users: [],
          properties: []
        };
        if (this.isEdit) {
          await this.$processReq(
            permissionService.updateSpecificObjectLevelGroupPermissions(
              this.$route.params.id,
              params
            )
          );
        } else {
          await this.$processReq(
            permissionService.createObjectLevelGroupPermissions(params)
          );
        }
        this.isSubmitting = false;
        this.$router.push({
          name: "profile_group"
        });
      } catch (err) {
        this.isSubmitting = false;
        console.log("Error: Unable to get user list - ", err);
      }
    },
    isSelected(item) {
      return this.selectedProfiles.includes(item);
    },
    toggle(item) {
      const index = this.selectedProfiles.indexOf(item);
      if (index === -1) {
        this.selectedProfiles.push(item);
      } else {
        this.selectedProfiles.splice(index, 1);
      }
    },
    toggleAll() {
      const currentPageProfileIds = this.profiles.map((profile) => profile.id);
      if (this.areAllSelected) {
        // Deselect all items on the current page
        this.selectedProfiles = this.selectedProfiles.filter(
          (id) => !currentPageProfileIds.includes(id)
        );
      } else {
        // Select all items on the current page
        this.selectedProfiles = [
          ...new Set([...this.selectedProfiles, ...currentPageProfileIds])
        ];
      }
    },

    async loadProfiles(blockLoading) {
      if (!blockLoading) this.isLoading = true;
      try {
        const params = {
          ...this.setPaginationParams()
        };
        const response = await this.$processReq(
          profileService.getProfileList(params),
          false
        );
        this.profiles = response.data.body.results;
        this.setPagination(response.data.body.count);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    async getSelectedGroupProfiles(val) {
      this.isLoading = true;
      try {
        const response = await this.$processReq(
          permissionService.getSpecificObjectLevelGroupPermissions(val),
          false
        );
        this.selectedProfiles = response.data.body.profiles;
        this.profileGroupName = response.data.body.name;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(err);
      }
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.loadProfiles();
    },
    "searchFilter.search": function () {
      this.pagination.page = 1;
      this.fetchProfiles(true);
    },
    row() {
      this.loadProfiles();
    }
  }
};
</script>

<style lang="css" scoped>
.re-text-center {
  text-align: center !important;
}
</style>
