<template>
  <div class="profiles">
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-layout class="pt-5" row wrap>
            <v-flex grow pa-1 xs12 sm5 md8>
              <v-layout align-start row wrap>
                <v-flex xs6 md4>
                  <h2 class="display-2 re-add-ml">
                    {{ $t("User Listing") }}
                  </h2>
                </v-flex>
                <v-flex xs6 md8> </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="flex-wrap" xs12 pa-1>
              <v-breadcrumbs :items="breadItemsSetLang()" class="re-add-ml">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <v-tabs>
            <v-tab>{{ $t("Property List") }}</v-tab>
            <v-tab-item lazy>
              <PropertyListing
                :selected="userDetails.properties"
                @update="userDetails.properties = $event"
              />
            </v-tab-item>
            <v-tab>{{ $t("Profile List") }}</v-tab>
            <v-tab-item lazy>
              <ProfileListing
                :selected="userDetails.profiles"
                @update="userDetails.profiles = $event"
              />
            </v-tab-item>
            <v-tab>{{ $t("Property Group List") }}</v-tab>
            <v-tab-item lazy>
              <GroupListing
                :selected="userDetails.object_level_permissions_groups"
                @update="userDetails.object_level_permissions_groups = $event"
                :type="1"
              />
            </v-tab-item>
            <v-tab>{{ $t("Profile Group List") }}</v-tab>
            <v-tab-item lazy>
              <GroupListing
                :selected="userDetails.object_level_permissions_groups"
                @update="userDetails.object_level_permissions_groups = $event"
                :type="2"
              />
            </v-tab-item>
          </v-tabs>
          <v-spacer></v-spacer>
          <div class="overflow-hidden text-xs-center pt-4">
            <v-btn dark @click="submit()" class="re-orangtbtn re-ml-4">
              {{ $t("Save") }}
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import GroupListing from "../../components/Permissions/GroupListing.vue";
import ProfileListing from "../../components/Permissions/ProfileListing.vue";
import PropertyListing from "../../components/Permissions/PropertyListing.vue";
import permissionService from "../../services/plugins/permission";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";

export default {
  name: "UserGroupCreateOrEdit",
  props: ["on"],
  mixins: [breadItemsSetLang],
  components: { PropertyListing, ProfileListing, GroupListing },
  data() {
    return {
      isLoading: true,
      userDetails: {}
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.getUserDetails();
    }
  },
  methods: {
    async getUserDetails() {
      try {
        this.isLoading = true;
        const response = await this.$processReq(
          permissionService.getSpecificUserDetails(this.$route.params.id),
          false
        );
        this.userDetails = response.data.body;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(err);
      }
    },
    async submit() {
      try {
        this.isLoading = true;
        const data = {
          properties: this.userDetails.properties,
          profiles: this.userDetails.profiles,
          object_level_permissions_groups:
            this.userDetails.object_level_permissions_groups
        };
        await this.$processReq(
          permissionService.updateSpecificUserDetails(
            this.$route.params.id,
            data
          )
        );
        this.$router.push({ name: "add_user_listing" });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(err);
      }
    }
  }
};
</script>

<style lang="css" scoped></style>
