var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","lg10":""}},[_c('v-layout',{staticClass:"pt-5",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"grow":"","pa-1":"","xs10":"","sm7":"","md8":""}},[_c('v-layout',{attrs:{"align-start":"","wrap":""}},[_c('h2',{staticClass:"display-2 pl-4"},[_vm._v(_vm._s(_vm.$t("User List")))])])],1),_c('v-flex',{attrs:{"shrink":"","pa-1":"","xs6":"","text-xs-right":""}})],1),_c('v-layout',[_c('v-flex',{staticClass:"flex-wrap",attrs:{"xs12":"","pa-1":""}},[_c('v-breadcrumbs',{staticClass:"pl-4",attrs:{"items":_vm.breadItemsSetLang()},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("fiber_manual_record")])]},proxy:true}])})],1)],1),(_vm.isLoading)?_c('table-loader'):_vm._e(),(!_vm.isLoading)?_c('v-card',[_c('v-toolbar',{staticClass:"re-toolbar re-wrap"},[_c('div',{staticClass:"re-flex"},[_vm._v(" "+_vm._s(_vm.$t("Rows Per Page"))+" "),_c('v-radio-group',{staticClass:"re-radios",model:{value:(_vm.row),callback:function ($$v) {_vm.row=$$v},expression:"row"}},[_c('v-radio',{attrs:{"label":_vm.$t('All'),"value":"show-all"}})],1)],1)]),_c('v-layout',{staticStyle:{"overflow":"auto"}},[_c('v-data-table',{staticClass:"width100",attrs:{"hide-actions":"","headers":_vm.headers,"items":_vm.usersList,"options":_vm.pagination,"server-items-length":_vm.pagination.totalItems,"no-data-text":_vm.$t('No data available')},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{on:{"click":function($event){_vm.dialog = true}}},[_c('td',{staticClass:"re-orange-color",class:{
                    'text-xs-right': _vm.$vuetify.rtl,
                    'text-xs-left': !_vm.$vuetify.rtl
                  }},[_c('div',[_vm._v(_vm._s(props.item.id))])]),_c('td',{staticClass:"pa-0 text-xs-center"},[_c('div',[_vm._v(_vm._s(props.item.email))])]),_c('td',{staticClass:"pa-0 text-xs-center"},[_c('div',[_vm._v(_vm._s(props.item.user_id))])]),_c('td',{staticClass:"re-td text-xs-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({},on),[_vm._v("more_vert")])]}}],null,true)},[_c('v-list',[_c('v-list-tile',{staticClass:"list_tile",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                            name: 'edit_user_permissions',
                            params: { id: props.item.id }
                          })}}},[_c('v-list-tile-title',[_vm._v(_vm._s(_vm.$t("Edit")))])],1)],1)],1)],1)])]}}],null,false,2621997834)})],1)],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"overflow-hidden text-xs-center pt-4"},[_c('v-pagination',{staticClass:"re-pagination",attrs:{"length":_vm.pages,"total-visible":10},on:{"input":function($event){return _vm.fetchData(false)}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }