<template>
  <div>
    <table-loader v-if="isLoading" />
    <v-card v-if="!isLoading">
      <v-toolbar height="auto" class="re-toolbar re-wrap">
        <div class="re-flex">
          {{ $t("Rows Per Page") }}
          <v-radio-group v-model="row" row class="re-radios">
            <v-radio :label="$t('All')" value="show-all"></v-radio>
          </v-radio-group>
        </div>
        <v-spacer></v-spacer>
        <v-text-field
          hide-details
          :placeholder="$t('Search')"
          v-model="searchFilter.search"
          single-line
        />
        <v-spacer />
      </v-toolbar>
      <v-layout style="overflow: auto">
        <v-data-table
          :headers="headers"
          :items="properties"
          :no-data-text="$t('No data available')"
          hide-actions
          class="elevation-1 width100"
          :options.sync="pagination"
          :server-items-length="pagination.totalItems"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th>
                <v-checkbox
                  :input-value="areAllSelected"
                  :indeterminate="
                    selectedProperties.length > 0 && !areAllSelected
                  "
                  @change="toggleAll"
                  primary
                  hide-details
                ></v-checkbox>
              </th>
              <th v-for="header in props.headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                <v-checkbox
                  :input-value="isSelected(props.item.id)"
                  @change="toggle(props.item.id)"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td class="re-text-center">
                {{ props.item.property_name }}
              </td>
              <td class="re-text-center">{{ props.item.address }}</td>
              <td class="re-text-center">
                {{
                  props.item.total_units === 0
                    ? "-----"
                    : props.item.total_units
                }}
              </td>
              <td class="re-text-center">
                {{
                  props.item.rented_units === 0
                    ? "-----"
                    : props.item.rented_units
                }}
              </td>
              <td class="re-text-center">
                {{
                  props.item.vacant_units === 0
                    ? "-----"
                    : props.item.vacant_units
                }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-layout>
    </v-card>
    <template>
      <div class="overflow-hidden text-xs-center pt-4">
        <v-pagination
          v-model="pagination.page"
          :length="pages"
          class="re-pagination"
          @input="loadProperties()"
        ></v-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from "lodash";

import propertyService from "../../services/plugins/properties";
import TableLoader from "../../views/loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";
export default {
  name: "PropertyListing",
  props: {
    selected: Array
  },
  mixins: [breadItemsSetLang, paginationMixin],
  components: { TableLoader },
  data() {
    return {
      isLoading: true,
      properties: [],
      selectedProperties: [],
      row: "",
      fetchProperties: null
    };
  },

  async created() {
    this.selectedProperties = this.selected || [];
    this.loadProperties();
  },
  mounted() {
    this.fetchProperties = debounce(this.loadProperties, 500);
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Property Name"),
          value: "property_name",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Address"),
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        { text: this.$t("Total Units"), align: "center", sortable: false },
        { text: this.$t("Rented Units"), align: "center", sortable: false },
        { text: this.$t("Vacant Units"), align: "center", sortable: false }
      ];
    },
    areAllSelected() {
      return this.properties.every((property) =>
        this.selectedProperties.includes(property.id)
      );
    }
  },
  methods: {
    isSelected(item) {
      return this.selectedProperties.includes(item);
    },
    toggle(item) {
      const index = this.selectedProperties.indexOf(item);
      if (index === -1) {
        this.selectedProperties.push(item);
      } else {
        this.selectedProperties.splice(index, 1);
      }
    },
    toggleAll() {
      const currentPagePropertyIds = this.properties.map(
        (property) => property.id
      );
      if (this.areAllSelected) {
        // Deselect all items on the current page
        this.selectedProperties = this.selectedProperties.filter(
          (id) => !currentPagePropertyIds.includes(id)
        );
      } else {
        // Select all items on the current page
        this.selectedProperties = [
          ...new Set([...this.selectedProperties, ...currentPagePropertyIds])
        ];
      }
    },

    async loadProperties(blockLoading) {
      if (!blockLoading) this.isLoading = true;
      try {
        const params = {
          ...this.setPaginationParams()
        };
        const response = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );
        this.properties = response.data.body.results;
        this.setPagination(response.data.body.count);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    }
  },
  watch: {
    selected: function () {
      this.selectedProperties = this.selected;
    },
    selectedProperties: function () {
      this.$emit("update", this.selectedProperties);
    },
    "$i18n.locale": function () {
      this.loadProperties();
    },
    "searchFilter.search": function () {
      this.pagination.page = 1;
      this.fetchProperties(true);
    },
    row() {
      this.loadProperties();
    }
  }
};
</script>

<style lang="css" scoped>
.re-text-center {
  text-align: center !important;
}
</style>
