<template>
  <div>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-layout class="pt-5" row wrap>
            <v-flex grow pa-1 xs12 sm5 md8>
              <v-layout align-start row wrap>
                <v-flex xs6 md4>
                  <h2 class="display-2 re-add-ml">
                    {{ $t("Property Group") }}
                  </h2>
                </v-flex>
                <v-flex xs6 md8> </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="flex-wrap" xs12 pa-1>
              <v-breadcrumbs :items="breadItemsSetLang()" class="re-add-ml">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <v-card>
            <v-card-text class="pa-3">
              <v-text-field
                v-model="propertyGroupName"
                :label="$t('Property Group Name')"
                :disabled="isEdit"
                :rules="[(value) => !!value || requiredMessage]"
              />
            </v-card-text>
          </v-card>
          <v-spacer></v-spacer>
          <table-loader v-if="isLoading" />
          <v-card v-if="!isLoading">
            <v-toolbar height="auto" class="re-toolbar re-wrap">
              <div class="re-flex">
                {{ $t("Rows Per Page") }}
                <v-radio-group v-model="row" row class="re-radios">
                  <v-radio :label="$t('All')" value="show-all"></v-radio>
                </v-radio-group>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                hide-details
                :placeholder="$t('Search')"
                v-model="searchFilter.search"
                single-line
              />
              <v-spacer />
            </v-toolbar>
            <v-layout style="overflow: auto">
              <v-data-table
                :headers="headers"
                :items="properties"
                :no-data-text="$t('No data available')"
                hide-actions
                class="elevation-1 width100"
                :options.sync="pagination"
                :server-items-length="pagination.totalItems"
              >
                <template slot="headers" slot-scope="props">
                  <tr>
                    <th>
                      <v-checkbox
                        :input-value="areAllSelected"
                        :indeterminate="
                          selectedProperties.length > 0 && !areAllSelected
                        "
                        @change="toggleAll"
                        primary
                        hide-details
                      ></v-checkbox>
                    </th>
                    <th v-for="header in props.headers" :key="header.text">
                      {{ header.text }}
                    </th>
                  </tr>
                </template>

                <template slot="items" slot-scope="props">
                  <tr>
                    <td>
                      <v-checkbox
                        :input-value="isSelected(props.item.id)"
                        @change="toggle(props.item.id)"
                        primary
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td class="re-text-center">
                      {{ props.item.property_name }}
                    </td>
                    <td class="re-text-center">{{ props.item.address }}</td>
                    <td class="re-text-center">
                      {{
                        props.item.total_units === 0
                          ? "-----"
                          : props.item.total_units
                      }}
                    </td>
                    <td class="re-text-center">
                      {{
                        props.item.rented_units === 0
                          ? "-----"
                          : props.item.rented_units
                      }}
                    </td>
                    <td class="re-text-center">
                      {{
                        props.item.vacant_units === 0
                          ? "-----"
                          : props.item.vacant_units
                      }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-layout>
          </v-card>
          <template>
            <div class="overflow-hidden text-xs-center pt-4">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                class="re-pagination"
                @input="loadProperties()"
              ></v-pagination>
            </div>
            <div class="overflow-hidden text-xs-center pt-4">
              <v-btn
                :loading="isSubmitting"
                dark
                @click="submit()"
                class="re-orangtbtn re-ml-4"
              >
                {{ $t("Save") }}
              </v-btn>
            </div>
          </template>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import { debounce } from "lodash";

import permissionService from "../../services/plugins/permission";
import propertyService from "../../services/plugins/properties";
import TableLoader from "../loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";
export default {
  name: "PropertyGroupCreateOrEdit",
  props: ["on"],
  mixins: [breadItemsSetLang, paginationMixin],
  components: { TableLoader },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      properties: [],
      selectedProperties: [],
      type: 1,
      row: "",
      isEdit: false,
      propertyGroupName: "",
      fetchProperties: null
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.getSelectedGroupProperties(this.$route.params.id);
    }
    this.loadProperties();
  },
  mounted() {
    this.fetchProperties = debounce(this.loadProperties, 500);
  },
  computed: {
    requiredMessage() {
      return this.$t("This field is required");
    },
    headers() {
      return [
        {
          text: this.$t("Property Name"),
          value: "property_name",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        {
          text: this.$t("Address"),
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        { text: this.$t("Total Units"), align: "center", sortable: false },
        { text: this.$t("Rented Units"), align: "center", sortable: false },
        { text: this.$t("Vacant Units"), align: "center", sortable: false }
      ];
    },
    areAllSelected() {
      return this.properties.every((property) =>
        this.selectedProperties.includes(property.id)
      );
    }
  },
  methods: {
    async submit() {
      if (!this.propertyGroupName) {
        this.propertyGroupName = null;
        return;
      }
      this.isSubmitting = true;
      try {
        let params = {
          type: this.type,
          name: this.propertyGroupName,
          properties: this.selectedProperties,
          users: [],
          profiles: []
        };
        if (this.isEdit) {
          await this.$processReq(
            permissionService.updateSpecificObjectLevelGroupPermissions(
              this.$route.params.id,
              params
            )
          );
        } else {
          await this.$processReq(
            permissionService.createObjectLevelGroupPermissions(params)
          );
        }
        this.isSubmitting = false;
        this.$router.push({
          name: "property_group"
        });
      } catch (err) {
        this.isSubmitting = false;
        console.log("Error: Unable to save property group - ", err);
      }
    },
    isSelected(item) {
      return this.selectedProperties.includes(item);
    },
    toggle(item) {
      const index = this.selectedProperties.indexOf(item);
      if (index === -1) {
        this.selectedProperties.push(item);
      } else {
        this.selectedProperties.splice(index, 1);
      }
    },
    toggleAll() {
      const currentPagePropertyIds = this.properties.map(
        (property) => property.id
      );
      if (this.areAllSelected) {
        // Deselect all items on the current page
        this.selectedProperties = this.selectedProperties.filter(
          (id) => !currentPagePropertyIds.includes(id)
        );
      } else {
        // Select all items on the current page
        this.selectedProperties = [
          ...new Set([...this.selectedProperties, ...currentPagePropertyIds])
        ];
      }
    },

    async loadProperties(blockLoading) {
      if (!blockLoading) this.isLoading = true;
      try {
        const params = {
          ...this.setPaginationParams()
        };
        const response = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );
        this.properties = response.data.body.results;
        this.setPagination(response.data.body.count);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    async getSelectedGroupProperties(val) {
      this.isLoading = true;
      try {
        const response = await this.$processReq(
          permissionService.getSpecificObjectLevelGroupPermissions(val),
          false
        );
        this.selectedProperties = response.data.body.properties;
        this.propertyGroupName = response.data.body.name;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(err);
      }
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.loadProperties();
    },
    "searchFilter.search": function () {
      this.pagination.page = 1;
      this.fetchProperties(true);
    },
    row() {
      this.loadProperties();
    }
  }
};
</script>

<style lang="css" scoped>
.re-text-center {
  text-align: center !important;
}
</style>
