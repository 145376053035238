<template>
  <div>
    <table-loader v-if="isLoading" />
    <v-card v-if="!isLoading">
      <v-toolbar class="re-toolbar re-wrap">
        <div class="re-flex">
          {{ $t("Rows Per Page") }}
          <v-radio-group v-model="row" class="re-radios">
            <v-radio :label="$t('All')" value="show-all" />
          </v-radio-group>
        </div>
      </v-toolbar>
      <v-layout style="overflow: auto">
        <v-data-table
          hide-actions
          class="width100"
          :headers="headers"
          :items="groupsList"
          :options.sync="pagination"
          :server-items-length="pagination.totalItems"
          :no-data-text="$t('No data available')"
        >
          <template slot="headers" slot-scope="props">
            <tr>
              <th>
                <v-checkbox
                  :input-value="areAllSelected"
                  :indeterminate="selectedGroups.length > 0 && !areAllSelected"
                  @change="toggleAll"
                  primary
                  hide-details
                ></v-checkbox>
              </th>
              <th v-for="header in props.headers" :key="header.text">
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <tr @click="dialog = true">
              <td>
                <v-checkbox
                  :input-value="isSelected(props.item.id)"
                  @change="toggle(props.item.id)"
                  primary
                  hide-details
                ></v-checkbox>
              </td>
              <td class="re-orange-color text-xs-center">
                <div>{{ props.item.id }}</div>
              </td>
              <td class="pa-0 text-xs-center">
                <div>{{ props.item.name }}</div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-layout>
    </v-card>
    <div class="overflow-hidden text-xs-center pt-4" v-if="!isLoading">
      <v-pagination
        v-model="pagination.page"
        :length="pages"
        :total-visible="10"
        class="re-pagination"
        @input="fetchData(false)"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import permissionService from "../../services/plugins/permission";
import TableLoader from "../../views/loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import paginationMixin from "@/mixins/paginationMixin";
export default {
  name: "GroupListing",
  mixins: [breadItemsSetLang, paginationMixin],
  components: { TableLoader },
  data() {
    return {
      isLoading: true,
      row: "",
      selectedGroups: [],
      groupsList: []
    };
  },
  props: {
    selected: Array,
    type: Number
  },
  async created() {
    this.selectedGroups = this.selected || [];
    this.fetchData();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ID"),
          value: "id",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Name"),
          align: "center",
          sortable: false,
          value: "name"
        }
      ];
    },
    areAllSelected() {
      return this.groupsList.every((property) =>
        this.selectedGroups.includes(property.id)
      );
    }
  },
  methods: {
    isSelected(item) {
      return this.selectedGroups.includes(item);
    },
    toggle(item) {
      const index = this.selectedGroups.indexOf(item);
      if (index === -1) {
        this.selectedGroups.push(item);
      } else {
        this.selectedGroups.splice(index, 1);
      }
    },
    toggleAll() {
      const currentPageGroupIds = this.groupsList.map((group) => group.id);
      if (this.areAllSelected) {
        // Deselect all items on the current page
        this.selectedGroups = this.selectedGroups.filter(
          (id) => !currentPageGroupIds.includes(id)
        );
      } else {
        // Select all items on the current page
        this.selectedGroups = [
          ...new Set([...this.selectedGroups, ...currentPageGroupIds])
        ];
      }
    },

    async fetchData(blockLoading) {
      if (!blockLoading) {
        this.isLoading = true;
      }
      try {
        let params = {
          ...this.setPaginationParams(),
          type: this.type
        };
        const resp = await this.$processReq(
          permissionService.getObjectLevelGroupPermissions(params),
          false
        );
        this.groupsList = resp.data.body.results;
        this.setPagination(resp.data.body.count);
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to get Propert Group list - ", err);
      }
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchData();
    },
    row() {
      this.fetchData();
    },
    selected: function () {
      this.selectedGroups = this.selected;
    },
    selectedGroups: function () {
      this.$emit("update", this.selectedGroups);
    }
  }
};
</script>
